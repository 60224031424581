body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  min-width: 800px;
  min-height: 100vh;
  overflow: auto;
}

.root {
  flex: 1 0 auto;
  display: flex;
}

.App {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  align-items: flex-start;
}

.App .header {
  margin-top: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
}

.App .header h1.title {
  margin-bottom: 0;
}
.App .header p.description {
  color: '#444';
  text-align: justify;
}

.App .canvas {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 800px;
  height: 350px;
}

.App .table {
  flex: 1 0 auto;
  align-self: stretch;
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 5px;
  margin-bottom: 5px;
  min-width: 800px;
  height: 350px;
}
